<template>
	<DxDataGrid
		ref="processStatus"
		:data-source="contentData"
		:show-borders="false"
		:show-column-headers="true"
		:show-column-lines="true"
		:show-row-lines="true"
		:row-alternation-enabled="false"
		:hover-state-enabled="true"
		:word-wrap-enabled="true"
		:no-data-text="this.$_msgContents('CMN_NO_DATA')"
		width="100%"
		height="350"
	>
		<DxFilterRow :visible="false" />
		<DxLoadPanel :enabled="true" />
		<DxPaging :enabled="false" />
		<DxScrolling mode="standard" />

		<DxColumn caption="상태" data-field="examProcess" alignment="center" :allowEditing="false" :allow-sorting="false">
			<DxLookup :data-source="codes.examProcess.dataSource" value-expr="value" display-expr="label" />
		</DxColumn>
		<DxColumn
			caption="변경일시"
			data-field="regDt"
			alignment="center"
			sortOrder="desc"
			:allowEditing="false"
			:allow-sorting="false"
			:calculate-display-value="rowData => this.$_commonlib.formatDate(rowData.regDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')"
		/>
		<DxColumn
			caption="수정자"
			data-field="agtNm"
			alignment="center"
			:allow-sorting="false"
			:calculate-display-value="rowData => `${rowData.agtNm} [${rowData.regId}]`"
		/>
	</DxDataGrid>
</template>

<script>
import { DxDataGrid, DxColumn, DxFilterRow, DxLookup, DxPaging, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid';


export default {
	components: {
		DxDataGrid,
		DxColumn,
		DxFilterRow,
		DxLookup,
		DxPaging,
		DxScrolling,
		DxLoadPanel,
	},
	props: {
		contentData: Array,
	},
	watch: {},
	data() {
		return {
			codes: {
				processCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				examProcess: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
			},
		};
	},
	computed: {},
	methods: {
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			//this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_process');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.initCodeMap().then(() => {
				this.codes.examProcess.dataSource = this.$_enums.ewm.examProcess.values;

				//this.$refs.processStatus.getGridInstance.columnOption('examProcess', 'lookup', this.codes.examProcess);
			});
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>
